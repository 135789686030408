import React, { useContext, useEffect, useState } from 'react'
import { CartContext } from './global/cartContext'
import '../css/cart.css';
import { auth } from './config/config';
import { Link, useNavigate } from 'react-router-dom';
import Icon from 'react-icons-kit';
import { ic_add } from 'react-icons-kit/md/ic_add';
import { ic_remove } from 'react-icons-kit/md/ic_remove';
import { iosTrashOutline } from 'react-icons-kit/ionicons/iosTrashOutline'
// import Navbar from './navbar';

const Cart = () => {
    //to check
    //const cartData = useContext(CartContext);
    //console.log(cartData)
    const {shoppingCart, dispatch, totalPrice, totalQnty } = useContext(CartContext);
    console.log(shoppingCart, "dispatch, totalPrice, totalQnty,",'value check')  
    const user = useState(auth.currentUser);
    const navigate = useNavigate();
    useEffect(()=>{
        //console.log(user.data(),'user');
        if(!user){
            navigate('/login', { replace: true });
            alert('You are not logged in.')
        }
    })

  return (
    <>
        {/* <Navbar/> */}
        <>   
            {shoppingCart.length !==0 && <h1>Cart</h1>}
            <div className='cart-container'>
                {
                    shoppingCart.length === 0 && <>
                        <div>no items in your cart or slow internet causing trouble (Refresh the page) or you are not logged in</div>
                        <div><Link to="/">Return to Home page</Link></div>
                    </>
                }

                {
                    shoppingCart && shoppingCart.map(cart =>(
                        //console.log(cart, 'cart data')
                        <div className='cart-card' key={cart.id}>
                            <div className='cart-img'>
                                <img src={cart.productImg} alt='product img here'></img>
                            </div>
                            <div className='cart-name' >{cart.productName}</div>
                            <div className='cart-price-orignal'>Rs.{cart.productPrice}.00</div>
                            <div className='inc' onClick={()=>dispatch({type:'INC', id:cart.id, cart})}>
                                <Icon icon={ic_add}></Icon>
                            </div>
                            <div className='quantity' >{cart.qty}</div>
                            <div className='dec' onClick={() => dispatch({type:'DEC', id:cart.id, cart })}>
                                <Icon icon={ic_remove} size={24}></Icon>
                            </div>

                            <div className='cart-price'>
                                Rs.{cart.TotalProductPrice}.00
                            </div>

                            <button className='delete-btn' onClick={() => dispatch({type:'DELETE', id: cart.id, cart })}>
                                <Icon icon={iosTrashOutline}></Icon>
                            </button>
                        </div>
                    ))
                }
                {shoppingCart.length>0 && <div className='cart-summary'>
                    <div className='cart-summary-heading'>
                        Cart-Summary
                    </div>
                    <div className='cart-summary-price'>
                        <span>Total Price</span>
                        <span>{totalPrice}</span>
                    </div>
                    <div className='cart-summary-price'>
                        <span>Total Qnty</span>
                        <span>{totalQnty}</span>
                    </div>
                    <Link to='/cashout' className='cashout-link'>
                        <button className='btn btn-success btn-md' style={{ marginTop: 5 + 'px' }}>
                            Cash on delivery
                        </button>
                    </Link>
                </div>}
            </div>
        </>
    </>
  )
}

export default Cart
