// Import the functions you need from the SDKs you need
//import { initializeApp } from "firebase/app";

import { initializeApp } from 'firebase/app';
import { getStorage } from 'firebase/storage';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from "firebase/auth";


// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBxOU80UOVfuY5jzu9N4r5A9PBxS4miBs0",
  authDomain: "ecommerce-project-c8d0e.firebaseapp.com",
  projectId: "ecommerce-project-c8d0e",
  storageBucket: "ecommerce-project-c8d0e.appspot.com",
  messagingSenderId: "1078476471092",
  appId: "1:1078476471092:web:87abd1656451a38fc8c361"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const storage = getStorage(app);
const db = getFirestore(app);
const auth = getAuth(app);

export { storage, db, auth }


//here this storage and db are the link to the my firebase database access. and another imported in the files are the function where we use our these storage and db, the names and function names
// are same so dont get confused.