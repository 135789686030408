import React, { useContext }  from "react";
import { ProductContext } from "./global/productContext";
import '../css/products.css';
import { CartContext } from "./global/cartContext";
import Carousel from 'react-bootstrap/Carousel';

const Products = () =>{
    const data = useContext(ProductContext);
    //console.log(data,'data')
    const productList = Object.values(data.products);
    // console.log(productList,'products')

    // const cartContextData = useContext(CartContext);
    // console.log(cartContextData,'cartContextData');
 
    const {dispatch} = useContext(CartContext);
    
    return(
      <>
        { productList.length !==0 && <h1>Available products</h1> }
        <div className="products-container">
          {productList.length === 0 && <div>Slow Internet....OR No product to display.</div> }
          {productList.map(product=>
            // {console.log(product, 'product')}
          (
            <div className="product-card" key={product.id}>
              <div className="product-img">
              <Carousel>
                {
                  Array.isArray(product.productImg) && product.productImg.map(images=>( 
                      <Carousel.Item style={{ width: "300px", height: "200px" }}>
                      <img key={images} src={images} alt="prod_img"></img>
                      </Carousel.Item>
                   ))
                }
              </Carousel>
                
              </div>
              <div className="product-name">
                <h6>{product.productName}</h6>
              </div>
              <div className="product-name" style={{color:"#2432f5"}}>
                {product.productDescription}
              </div>
              {/* <div className="crossed-Price">
                {product.orignalPrice}
              </div> */}
              <div className="product-price justify-content-center" style={{color:"#c33131", fontWeight: 'bolder'}}>
                NRS:- < div className="crossed-Price" style={{color:"#a5a9ad", fontWeight: '500' }}>{product.orignalPrice}</div> {product.productPrice}
              </div>
                {/* <button className="addCart-btn" >ADD TO CARD</button>  */}
                <button className="addCart-btn" onClick={() => {dispatch({type:'ADD_TO_CART' , id: product.productId, product})}} >ADD TO CART</button> 
            </div>
          )
          )}
        </div>
      </>
    )
}

export default Products;