import React, { useEffect, useState } from 'react';
import '../css/addProducts.css';
import { storage, db } from './config/config';
import { collection, addDoc, getDocs } from 'firebase/firestore';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';

const AddProducts = () => {
  const [productName, setProductName] = useState('');
  const [productDescription, setProductDescription] = useState('');
  const [ orignalPrice, setOrignalPrice] = useState(0);
  const [productPrice, setProductPrice] = useState(0);
  const [productImgs, setProductImgs] = useState([]); // Change state to an array to store multiple files
  const [error, setError] = useState('');

  const types = ['image/png', 'image/jpeg', 'image/jpg'];

  const productImageHandler = (e) => {
    let selectedFiles = e.target.files;
    let selectedFilesArray = [];

    for (let i = 0; i < selectedFiles.length; i++) {
      if (selectedFiles[i] && types.includes(selectedFiles[i].type)) {
        selectedFilesArray.push(selectedFiles[i]);
      }
    }

    if (selectedFilesArray.length > 0) {
      setProductImgs(selectedFilesArray);
      setError('');
    } else {
      setProductImgs([]);
      setError(`Please select valid image types: png, jpeg`);
    }
  };

  const getProduct = async () => {
    var productData = collection(db, 'products');
    var result = await getDocs(productData);
    result.docs.forEach((e) => {
      //console.log(e.data(), 'data');
    });
  };

  const addProducts = async (e) => {
    e.preventDefault();
    const uploadTasks = []; // Array to store all upload tasks

    // Upload all files in productImgs array
    for (let i = 0; i < productImgs.length; i++) {
      const imgRef = ref(storage, `images/${productImgs[i].name}`);
      const imageData = uploadBytesResumable(imgRef, productImgs[i]);
      uploadTasks.push(imageData);
    }

    // Wait for all the upload tasks to complete
    try {
      await Promise.all(uploadTasks.map((task) => task));

      const downloadURLs = await Promise.all(
        uploadTasks.map((task) => getDownloadURL(task.snapshot.ref))
      );

    //console.log('File available at', downloadURL);
        var body = {
            "productName" : productName,
            "orignalPrice" : orignalPrice,
            "productPrice" : productPrice,
            "productDescription" : productDescription,
            "productImg" : downloadURLs
        };

        var productData = collection(db, "products");
        await addDoc(productData, body).then((response) =>{
            alert('Product added successfully');
            setProductName('');
            setProductPrice(0);
            setProductImgs([]);
            setProductDescription('');
            setError('');
            document.getElementById('file').value = '';
        });
      
    } catch (error) {
      setError(error.message || 'Error uploading files');
    }
  };

  useEffect(() => {
    getProduct();
  }, []);

  return (
    <div className='container'>
      <br />
      <h2>Add Products</h2>
      <hr />
      <form autoComplete='off' className='form-group' onSubmit={addProducts}>
        <label htmlFor='product-name'>Product Name:</label>
        <br />
        <input type='text' className='form-control' required onChange={(e) => setProductName(e.target.value)} value={productName}/>
        <br />

        <label htmlFor='orignal-price'>Orignal Price:</label>
            <br/>
            <input type='number' className='form-control' required onChange={(e)=>setOrignalPrice(e.target.value)} value={orignalPrice}/>
        <br/>

        <label htmlFor='product-price'>Product Price:</label>
        <br />
        <input type='number' className='form-control' required onChange={(e) => setProductPrice(e.target.value)} value={productPrice}/>
        <br />

        <label htmlFor='product-description'>Product Description:</label>
        <br />
        <input type='text' className='form-control' required onChange={(e) => setProductDescription(e.target.value)} value={productDescription} />
        <br />

        <label htmlFor='product-img'>Product Images:</label>
        <input type='file' id='file' className='form-control' onChange={productImageHandler} required multiple />

        <br />
        <button className='btn btn-success btn-md mybtn'>Add Now</button>
      </form>
      {error && <span>{error}</span>}
    </div>
  );
};

export default AddProducts;



// import React, { useEffect, useState } from 'react'
// import '../css/addProducts.css';
// import { storage, db } from './config/config';
// import { collection, addDoc, getDocs } from 'firebase/firestore';
// import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';

// //getDocs is used in fetching data from firebase
// // addDoc is needed when we are not passing id i.e create new,
// // setDoc is needed when we pass id i.e Update


// const AddProducts = () => {

//     const [ productName, setProductName ] = useState([]);
//     const [ productDescription, setProductDescription ] = useState([]);
//     const [ productPrice, setProductPrice ] = useState(0);
//     const [ productImg, setProductImg ] = useState(null);
//     const [ error, setError ] = useState('');

//     const types = ['image/png', 'image/jpeg']

//     const productImageHandler = (e) =>{
//         let selectedFile = e.target.files[0];
//         if(selectedFile && types.includes(selectedFile.type)){
//             setProductImg(selectedFile);
//             setError('');
//         }else{
//             setProductImg(null);
//             setError(`Please select valid image type png, jpeg`);
//         }
//     }

//     const  getProduct = async(e) =>{
//         var productData = collection(db, "products");
//         var result = await getDocs(productData);
//         result.docs.forEach(e=>{
//             //console.log(e.data(),'data')
//         });
//     }

//     const addProducts = async(e) =>{
//         e.preventDefault();
//        //console.log(productImg.name, 'productImg');
//         const imgRef = ref(storage, `images/${productImg.name}`);
//         const imageData = uploadBytesResumable(imgRef, productImg);

//         imageData.on('state_changed', 
//             (snapshot) => {
//                 // Observe state change events such as progress, pause, and resume
//                 // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
//                 const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
//                 console.log('Upload is ' + progress + '% done');
//                 switch (snapshot.state) {
//                     case 'paused':
//                         console.log('Upload is paused');
//                         break;
//                     case 'running':
//                         console.log('Upload is running');
//                         break;
//                     default:
//                         console.log('Unknown state/Error state');
//                         break;
//                 }
//             }, 
//             (error) => {
//                 setError(error);
//             }, 
//             () => {
//                 // Handle successful uploads on complete
//                 // For instance, get the download URL: https://firebasestorage.googleapis.com/...
//                 getDownloadURL(imageData.snapshot.ref).then( async (downloadURL) => {
//                 //console.log('File available at', downloadURL);
//                     var body = {
//                         "productName" : productName,
//                         "productPrice" : productPrice,
//                         "setProductDescription" : productDescription,
//                         "productImg" : downloadURL
//                     };
            
//                     var productData = collection(db, "products");
//                     await addDoc(productData, body).then((response) =>{
//                         alert('Product added successfully');
//                         //console.log(response,'response');
//                         setProductName('');
//                         setProductPrice(0);
//                         setProductImg('');
//                         setProductDescription('')
//                         setError('');
//                         document.getElementById('file').value = '';
//                     });
//                 });
//             }
//         );
//     }

//     useEffect(()=>{
//         getProduct();
//     },[])

//   return (
//     <div className='container'>
//         <br/>
//         <h2>Add Products</h2>
//         <hr/>
//         <form autoComplete='off' className='form-group' onSubmit={addProducts}>
//             <label htmlFor='product-name'>Product Name:</label>
//             <br/>
//             <input type='text' className='form-control' required onChange={(e)=>setProductName(e.target.value)} value={productName}/>
//             <br/>

//             <label htmlFor='product-price'>Product Price:</label>
//             <br/>
//             <input type='number' className='form-control' required onChange={(e)=>setProductPrice(e.target.value)} value={productPrice}/>
//             <br/>

//             <label htmlFor='product-description'>Product Description:</label>
//             <br/>
//             <input type='text' className='form-control' required onChange={(e)=>setProductDescription(e.target.value)} value={productDescription}/>
//             <br/>
    
//             <label htmlFor='product-img'>Product Image:</label>
//             <input type='file' id='file' className='form-control' onChange={productImageHandler} required />
            
//             <br/>
//             <button className='btn btn-success btn-md mybtn'>Add Now</button>
//         </form>
//         {error&&<span>{error}</span>}
//     </div>
//   )
// }

// export default AddProducts;
