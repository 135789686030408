import React, { Component } from "react";
import { BrowserRouter, Routes ,Route } from "react-router-dom";
import  Home  from "./components/home";
import AddProducts from "./components/addProducts";
import { ProductContextProvider } from "./components/global/productContext";
import SignUp from "./components/signUp";
import Login from "./components/login";
import { CartContextProvider } from "./components/global/cartContext";
import Cart from "./components/cart";
import Cashout from "./components/cashOut";
import ProductAction from "./components/productAction";
import CartAction from "./components/myCart";
import { UserCartContextProvider } from "./components/global/userCartContext";
import NavBar from "./components/navbar";
// import Footer from "./components/footer";

export class App extends Component{

  render(){
    return(
      <ProductContextProvider>
        <UserCartContextProvider>
          <CartContextProvider>
            <BrowserRouter>
              <NavBar/>
              <Routes>
                <Route exact path='/' Component={Home} />
                <Route exact path="/addProducts" Component={AddProducts}/>
                <Route path="/signUp" Component={SignUp}/>
                <Route path="/login" Component={Login}/>
                <Route path="/cartProducts" Component={Cart} />
                <Route path="/cashout" Component={Cashout}/>
                <Route path="/productAction" Component={ProductAction}/>
                <Route path="/myCart" Component={CartAction}/>
              </Routes>
              {/* <Footer/> */}
            </BrowserRouter>
          </CartContextProvider>
        </UserCartContextProvider>
      </ProductContextProvider>
    )
  }
}

export default App;