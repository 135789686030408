import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate  } from "react-router-dom";
import logo from './images/logo.png';
import {Icon} from 'react-icons-kit';
import {cart} from 'react-icons-kit/entypo/cart'
import { signOut } from "firebase/auth";
import {auth, db} from './config/config';
import '../css/navbar.css';
import { doc, onSnapshot } from 'firebase/firestore';
import { CartContext } from './global/cartContext';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';

const NavBar = () => {
   
  const navigate = useNavigate();
  
  const { totalQnty } = useContext(CartContext);

  const [ user , setUser ] = useState(auth.currentUser);

  const [ role, setRole ] = useState('');

  const logout = async() =>{
    await signOut(auth).then(() => {
      navigate('/login', { replace: true });
      alert('logout successfull')
    }).catch((error) => {
      alert(`${error.message}`);
    });
  }

  useEffect(()=>{
    auth.onAuthStateChanged( async(currentUser)=>{
      console.log(currentUser,'auth user');
      setUser(currentUser); //setting state of current user.
      if(currentUser !== null){
        //getting data from another collection in real time //snapshot will update it realtime.
        var userData = doc(db, 'users', currentUser.uid);
        onSnapshot(userData, (result)=>{
          //console.log(result.data(),'resutlt snapshort');
          if (result.exists() && result.data().role){
            let userRole = result.data().role;
            if(userRole) setRole(userRole);
            localStorage.setItem('auth',currentUser.uid);
            localStorage.setItem('role',userRole); // temporaty option to do this
          }  
        })
      }
      else localStorage.removeItem('auth');
    });
  },[]);

  return (

    <>
      <Navbar className="bg-body-tertiary">
        <Container>
         <div className='navbar'>
           <Link to='/' className='title'>
             <div className='leftMenu'>
                 <img className='logo' src={logo} alt='logoHere'></img>
                 <p className="site-title">
                   Carry All Essentials
                 </p>
             </div>
           </Link>
        
             {!user && <div className='rightMenu'>
                 <Link to='/signup' className='navlinks'>SignUp</Link>
                 <Link to='/login' className='navlinks'>Login</Link>
             </div>}
             {user && <div className='rightMenu'>
               {/* <span><Link to = '/' className='navlinks'>{user.displayName}</Link></span> */}
               <h5 className='me-2'>{user.displayName}</h5>
               <span><Link to = '/cartProducts'><Icon icon = {cart}/></Link></span>
               <span className='no-of-products me-2' style={{color:'red', fontWeight:'bold'}}>{totalQnty}</span>
               <span><button className='btn btn-danger btn-sm me-1' onClick={logout} >Logout</button></span>
               <button type="button" className="btn btn-secondary btn-sm me-1" onClick={()=>{navigate('/myCart', {replace:true})}}>My Cart</button>
               {
                 role === 'admin' && <div div className='rightMenu'>
                 <button type="button" className="btn btn-primary btn-sm me-5" onClick={()=>{navigate('/productAction', {replace:true})}}>Product Action</button>
                 </div>
               }
             </div> 
             }
         </div>




         {/* <Link to='/' className='title'>
           <div className='leftMenu'>
               <img className='logo' src={logo} alt='logoHere'></img>
               <p className="site-title">
                 Carry All Essentials
               </p>
           </div>
         </Link>
          <Navbar.Toggle />
          <Navbar.Collapse className="justify-content-end">
              {!user && <div className='rightMenu'>
                <Link to='/signup' className='navlinks'>SignUp</Link>
                <Link to='/login' className='navlinks'>Login</Link>
            </div>}
            {user && <div className='rightMenu'>

              <h5 className='me-2'>{user.displayName}</h5>
              <span><Link to = '/cartProducts'><Icon icon = {cart}/></Link></span>
              <span className='no-of-products me-2' style={{color:'red', fontWeight:'bold'}}>{totalQnty}</span>
              <span><button className='btn btn-danger btn-sm me-1' onClick={logout} >Logout</button></span>
              <button type="button" className="btn btn-secondary btn-sm me-1" onClick={()=>{navigate('/myCart', {replace:true})}}>My Cart</button>
              {
                role === 'admin' && <div div className='rightMenu'>
                <button type="button" className="btn btn-primary btn-sm me-5" onClick={()=>{navigate('/productAction', {replace:true})}}>Product Action</button>
                </div>
              }
            </div> 
            }

          </Navbar.Collapse> */}
        </Container>
      </Navbar>
    </>



    // <div className='navbar'>
    //   <Link to='/' className='title'>
    //     <div className='leftMenu'>
    //         <img className='logo' src={logo} alt='logoHere'></img>
    //         <p className="site-title">
    //           Carry All Essentials
    //         </p>
    //     </div>
    //   </Link>
        
    //     {!user && <div className='rightMenu'>
    //         <Link to='/signup' className='navlinks'>SignUp</Link>
    //         <Link to='/login' className='navlinks'>Login</Link>
    //     </div>}
    //     {user && <div className='rightMenu'>
    //       {/* <span><Link to = '/' className='navlinks'>{user.displayName}</Link></span> */}
    //       <h5 className='me-2'>{user.displayName}</h5>
    //       <span><Link to = '/cartProducts'><Icon icon = {cart}/></Link></span>
    //       <span className='no-of-products me-2' style={{color:'red', fontWeight:'bold'}}>{totalQnty}</span>
    //       <span><button className='btn btn-danger btn-sm me-1' onClick={logout} >Logout</button></span>
    //       <button type="button" className="btn btn-secondary btn-sm me-1" onClick={()=>{navigate('/myCart', {replace:true})}}>My Cart</button>
    //       {
    //         role === 'admin' && <div div className='rightMenu'>
    //         <button type="button" className="btn btn-primary btn-sm me-5" onClick={()=>{navigate('/productAction', {replace:true})}}>Product Action</button>
    //         </div>
    //       }
    //     </div> 
    //     }
    // </div>
  )
}

export default NavBar

