import React, {createContext} from "react";
import { db } from '../config/config';
import { collection, getDocs, query, where } from 'firebase/firestore';
//import { ref } from "firebase/storage";
//import { onValue } from "firebase/database";

//declearation of context must start with initial uppercase
export const UserCartContext = createContext();

export class UserCartContextProvider extends React.Component{

    //defining an initial state
    state={
        userCart:[]
    }

    componentDidMount  = async() => {
        const userId = localStorage.getItem('auth');
       if(userId){ const userId = localStorage.getItem('auth');
        const usersRef = collection(db, "orderCart");
        const q = query(usersRef, where("userId", "==", userId));
        
        getDocs(q).then((querySnapshot) => {
            if (!querySnapshot.empty) {
                const finalData = querySnapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data()
                }));
                this.setState({
                    userCart: finalData
                });

            } else {
            // Data not found for the given userId
                console.log("Data not found.");
            }
        })
        .catch((error) => {
            console.error("Error fetching data:", error);
        });}
    }

    render(){
        return(
            <UserCartContext.Provider value={{userCart:{...this.state.userCart}}}>
                {this.props.children}
            </UserCartContext.Provider>
        )
    }
}