import React, { useContext, useEffect, useState } from 'react'
// import Navbar from './navbar'
import { ProductContext } from './global/productContext';
import '../css/productAction.css';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import { db, storage } from './config/config';
import { deleteDoc, doc, updateDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import Carousel from 'react-bootstrap/Carousel';

const ProductAction = () => {
    const data = useContext(ProductContext);
    const productList = Object.values(data.products);
    const [show, setShow] = useState(false);
    const [editData, setEditData] = useState([]);
    const navigate = useNavigate();
    //const handleClose = () => setShow(false);
    // const handleShow = () => setShow(true);

    const [ productId, setProductId ] = useState('');
    const [ productName, setProductName ] = useState('');
    const [ productDescription, setProductDescription ] = useState('');
    const [ orignalPrice, setOrignalPrice] = useState(0);
    const [ productPrice, setProductPrice ] = useState(0);
    const [ productImgs, setProductImgs ] = useState([]);
    const [ error, setError ] = useState('');

    const types = ['image/png', 'image/jpeg', 'image/jpg']

    const productImageHandler = (e) => {
        let selectedFiles = e.target.files;
        let selectedFilesArray = [];
    
        for (let i = 0; i < selectedFiles.length; i++) {
          if (selectedFiles[i] && types.includes(selectedFiles[i].type)) {
            selectedFilesArray.push(selectedFiles[i]);
          }
        }
    
        if (selectedFilesArray.length > 0) {
          setProductImgs(selectedFilesArray);
          setError('');
        } else {
          setProductImgs([]);
          setError(`Please select valid image types: png, jpeg`);
        }
      };

    const alterFunction = async (body) =>{
        var productData = doc(db, "products", productId);
        await updateDoc(productData, body).then(() =>{
            alert('Product updated successfully');
            setShow(false);
            setProductName('');
            setProductDescription('');
            setOrignalPrice(0);
            setProductPrice(0);
            setProductImgs([]);
            document.getElementById('file').value = '';
            window.location.reload();
        }).catch(error=>{
            alert(`${error.message}`);
            setShow(false);
        })
    }

    const editProducts = async(e) =>{
        e.preventDefault();
       //console.log(productImg.name, 'productImg');
        

        if(productImgs.length>0){
            const uploadTasks = []; // Array to store all upload tasks

            // Upload all files in productImgs array
            for (let i = 0; i < productImgs.length; i++) {
              const imgRef = ref(storage, `images/${productImgs[i].name}`);
              var imageData = uploadBytesResumable(imgRef, productImgs[i]);
              uploadTasks.push(imageData);
            }

            await Promise.all(uploadTasks.map((task) => task));

            const downloadURLs = await Promise.all(
                uploadTasks.map((task) => getDownloadURL(task.snapshot.ref))
            );

            //console.log('File available at', downloadURL);
                var body = {
                    "productName" : productName,
                    "orignalPrice" : orignalPrice,
                    "productPrice" : productPrice,
                    "productDescription" : productDescription,
                    "productImg" : downloadURLs
                };

            alterFunction(body);
        }
        else{
            let body = {
                "productName" : productName,
                "orignalPrice" : orignalPrice,
                "productPrice" : productPrice,
                "productDescription" : productDescription
            };
            alterFunction(body);
        }
    }

    

    const deleteProduct = async(id) =>{
        console.log(id,'product id');
        const result = window.confirm('Do you want to proceed?');
        console.log(result,'delete result')
        if(result){
            await deleteDoc(doc(db, 'products', id)).then(()=>{
                alert("ProductId Deleted Successfully");
                window.location.reload();
            }).catch(err=>{
                alert(err.message);
            })
        }
        
    }

    useEffect(()=>{

    },[]);


  return (
    <>
        {/* <Navbar/> */}

        <Modal show={show} onHide={() => setShow(false)} dialogClassName="modal-90w" aria-labelledby="example-custom-modal-styling-title">
            <Modal.Header closeButton>
            <Modal.Title id="example-custom-modal-styling-title">
                Edit Product details
            </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            {/* <p>
                {show && console.log(editData, 'editData')}
            </p> */}
            <>
                {editData &&
                    <form autoComplete='off' className='form-group' onSubmit={editProducts}>
                        <label htmlFor='product-name'>Product Name:</label>
                        <br/>
                        <input type='text' className='form-control' required onChange={(e)=>setProductName(e.target.value)} value={productName}/>
                        <br/>

                        <label htmlFor='orignal-price'>Orignal Price:</label>
                        <br/>
                        <input type='number' className='form-control' required onChange={(e)=>setOrignalPrice(e.target.value)} value={orignalPrice}/>
                        <br/>

                        <label htmlFor='product-price'>Product Price:</label>
                        <br/>
                        <input type='number' className='form-control' required onChange={(e)=>setProductPrice(e.target.value)} value={productPrice}/>
                        <br/>

                        <label htmlFor='product-description'>Product Description:</label>
                        <br/>
                        <input type='text' className='form-control' required onChange={(e)=>setProductDescription(e.target.value)} value={productDescription}/>
                        <br/>
                
                        <label htmlFor='product-img'>Product Image:</label>
                        <input type='file' id='file' multiple className='form-control' onChange={productImageHandler} />
                        
                        <br/>
                        <button className='btn btn-success btn-md mybtn'>Apply Changes</button>
                        {error && <span>{error}</span>}
                    </form>
                }
            </>
            </Modal.Body>
            <Modal.Footer>
                {/* <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleClose}>
                    Save Changes
                </Button> */}
        </Modal.Footer>
        </Modal>

        <div>
            {/* <button className="btn btn-Primary" onClick={()=>{navigate('/addProducts', {replace:true})}}>Add Product</button> */}
            <Button variant="primary mybtn me-5" style={{width:'15%'}} onClick={()=>{navigate('/addProducts', {replace:true})}}>Add Product</Button>
        </div>
        { productList.length !==0 && <h1>Products List</h1> }
        <div className="products-container">
        {productList.length === 0 && <div>Slow Internet....OR No product to display.</div> }
            {productList.map(product=>(
                <div className="product-card" key={product.id}>
                    <div className="row"  style={{backgroundColor:'#94bbe9'}}>
                        <div className=" product-img">
                            <Carousel>
                                {
                                Array.isArray(product.productImg) && product.productImg.map(images=>( 
                                    <Carousel.Item style={{ width: "300px", height: "200px" }}>
                                    <img key={images} src={images} alt="prod_img"></img>
                                    </Carousel.Item>
                                ))
                                }
                            </Carousel>
                            {/* <img className="card-img" src={product.productImg} alt="image_here" width={180} height={180} style={{padding: '10px', borderRadius: '15%'}} /> */}
                        </div>
                        <div className="col-sm-7 text-section" style={{paddingTop:'10px', width:"100%"}}>
                            {/* <div className="card-body"> */}
                                <h5 className="card-title fw-bold" style={{color:'#1200ff', fontWeight:'bold'}}>{product.productName}</h5>
                                <h6 className="card-title fw-bold" style={{color:'#a77243', fontWeight:'bold'}}>{product.productDescription}</h6>
                                <div className="product-price justify-content-center" style={{color:"#c33131", fontWeight: 'bolder'}}>
                                    NRS:- < div className="crossed-Price" style={{color:"black", fontWeight: '500' }}>{product.orignalPrice}</div> {product.productPrice}
                                </div>
                                {/* <p className="card-text" style={{fontWeight:'bold', textDecoration: 'line-through'}}>Price: {product.orignalPrice}</p>
                                <p className="card-text" style={{fontWeight:'bold'}}>Price: {product.productPrice}</p> */}
                            {/* </div> */}
                        </div>
                        <div className="d-flex justify-content-end" style={{backgroundColor:'#94e9bd'}}>
                            {/* <button type="button" className="btn btn-primary me-2" data-toggle="modal" data-target=".bd-example-modal-lg">Update</button> */}
                            <Button variant="primary me-2" onClick={() => {setShow(true); setEditData(product); setProductId(product.id); setProductName(product.productName); setProductPrice(product.productPrice); setProductDescription(product.productDescription); setOrignalPrice(product.orignalPrice)}}> Update </Button>
                            <button className="btn btn-danger" onClick={()=>deleteProduct(product.id)}>Delete</button>
                        </div>
                    </div>
                </div>
            ))}
        </div>
        
    </>
  )
}

export default ProductAction



