export const CartReducer = ( state, action ) => {
    const { shoppingCart, totalPrice, totalQnty  } = state;

    let product;
    let index;
    let updatePrice;
    let updatedQnty;

    switch(action.type){
        case 'ADD_TO_CART':
            //console.log(shoppingCart,'shoppingCart')
            console.log('action',action.product)
            const check = shoppingCart.find(product => product.id === action.product.id);
            
            if(check){
                alert('Product is already in cart.');
                return state;
            }
            else{
                product = action.product;
                product['qty'] = 1;
                product['TotalProductPrice'] = Number(product.productPrice) * Number(product.qty);
                updatedQnty = Number(totalQnty) + 1;
                updatePrice = Number(totalPrice) + Number(product.productPrice);
                alert('Product added successfully');
                return{
                    shoppingCart: [product, ...shoppingCart], 
                    totalPrice: updatePrice, 
                    totalQnty: updatedQnty
                }
            }

        case 'INC':
            product = action.cart;
            product.qty = ++product.qty;
            product.TotalProductPrice = Number(product.qty) * Number(product.productPrice);
            updatedQnty = Number(totalQnty) + 1;
            updatePrice = Number(totalPrice) + Number(product.productPrice);
            index = shoppingCart.findIndex(cart => cart.id === action.id);
            shoppingCart[index] = product;
            return {
                shoppingCart: [...shoppingCart], totalPrice: updatePrice, totalQnty: updatedQnty 
            }

        case 'DEC':
            product = action.cart;
            if(product.qty > 1){
                product.qty = Number(product.qty) - 1;
                product.TotalProductPrice = Number(product.qty) * Number(product.productPrice);
                updatePrice = Number(totalPrice) - Number(product.productPrice);
                updatedQnty = Number(totalQnty) - 1;
                index = shoppingCart.findIndex(cart => cart.id === action.id);
                shoppingCart[index] = product;
                return {
                    shoppingCart: [...shoppingCart], totalPrice: updatePrice, totalQnty: updatedQnty 
                }
            }
            else {
                return state;
            }

        case 'DELETE':
            const filtered = shoppingCart.filter(product => product.id !== action.id);
            product = action.cart;
            updatedQnty = Number(totalQnty) - Number(product.qty);
            updatePrice = Number(totalPrice) - Number(product.qty) * Number(product.productPrice);
            return{
                shoppingCart: [...filtered], totalPrice: updatePrice, totalQnty: updatedQnty
            }
            
        default:
            return state;
    }
}
