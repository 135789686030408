import React, { useEffect, useState } from 'react';
import {collection, getDocs, setDoc, doc} from 'firebase/firestore';
import { db, auth } from './config/config';
import {  createUserWithEmailAndPassword, updateProfile } from "firebase/auth";
import { Link, useNavigate } from 'react-router-dom';
// import Navbar from './navbar';

const SignUp = () => {

  //defining state using useState hooks.

  const [ name, setName ] = useState('');
  const [ email, setEmail ] = useState('');
  const [ password, setPassword ] = useState('');
  const [ cnfPassword, setCnfPassword ] = useState('');
  const [ error, setError ] = useState('');
  const navigate = useNavigate();

  const checkEmail = async() =>{
    let emails = [];
    let connection =  collection(db, 'users');
    await getDocs(connection).then((userData)=>{
      userData.docs.forEach(e=>{
        emails.push(e.data().email);
      });
    });
    if(emails.includes(email)) {
      return 1;
    } 
    else return 0;
  }

  const signUp = async(e) =>{
    e.preventDefault();    
    if(name === '' || !name) {
      alert('Name is missing');
      return;
    }
    else if(email === '' || !email) {
      alert('Email is missing');
      return;
    }
    else if(password === '' || !password || cnfPassword === '' || !cnfPassword)
    {
      alert('Any of the password field cannot be empty');
      return;
    }

    var result = await checkEmail();
    if(result === 1){
      alert('Email you are trying to use is in use, please select another');
      return;
    }

    if(password === cnfPassword){
      let body = {
        "name" : name,
        "email" : email,
        "password" : password,
        "role" : "user"
      }

      //let ath = auth;
      await createUserWithEmailAndPassword(auth, email, password).then(async(userCredential) => {
        const user = userCredential.user;
        await updateProfile(user,{
          displayName: name
        })

        // thisbellow process will create auth user and users collection with two diffrent uids
        // console.log("User created:", user);
        // var userData = collection(db, 'users');
        // await setDoc(userData, body).then(result=>{

        //this procedd will create auth user and users collection with same UId
          var userData = collection(db, 'users');
          await setDoc(doc(userData, user.uid), body).then(result=>{
          setName('');
          setEmail('');
          setPassword('');
          setCnfPassword('');
          setError('');
          alert('User added successfully');
          // console.log(result,'response');
          window.location.reload();
          navigate('/', { replace:true });
        }).catch(e=>{
          setError(e,'error');
        });
      }).catch((error) => {
        alert(`${error.message}`);
        setError(error.message);
      });
    }
    else{
      alert('Password doesnt matched.')
    }

  }

  useEffect(()=>{
    if(localStorage.getItem('auth')) navigate('/', { replace:true });
  },[navigate]);

  return (
    <>
    {/* <Navbar/> */}
      <div className='container'>
        <h2>SignUp</h2>
        <hr/>
        <form autoComplete='off' className='form-group' onSubmit={signUp}>
          <label htmlFor='Name'>Name:</label>
          <br/>
          <input type='text' className='form-control' required onChange={(e)=>{setName(e.target.value)}}></input>
          <br/>

          <label htmlFor='Email'>Email</label>
          <br/>
          <input type='email' className='form-control' required onChange={(e)=>{setEmail(e.target.value)}}></input>
          <br/>

          <label htmlFor='Password'>Password</label>
          <br/>
          <input type='password' className='form-control' required onChange={(e)=>{setPassword(e.target.value)}}></input>
          <br/>

          <label htmlFor='confirmPassword'>Confirm Password</label>
          <br/>
          <input type='password' className='form-control' required onChange={(e)=>{setCnfPassword(e.target.value)}}></input>
          <br/>

          <button type='submit' className='btn btn-success btn-md mybtn'>REGISTER</button>
        </form>
        {error && <div className='error-msg'>{error}</div>}
        <br/>
        <span>Already have account? Login.
          <Link to='/login'> Here</Link>
        </span>
      </div>
    </>
  )
}

export default SignUp;
