import React, { useContext, useEffect } from 'react'
// import Navbar from './navbar'
import { UserCartContext } from './global/userCartContext';
import { deleteDoc, doc } from 'firebase/firestore';
import { db } from './config/config';



const MyCart = () => {

  const data = useContext(UserCartContext);
  const productList = Object.values(data.userCart);
  const role = localStorage.getItem('role');
  console.log(productList,'cart context data',data,'role', role);

  const cancelOrder = async(id) =>{
    console.log(id,'product id');
    const result = window.confirm('Do you want to proceed?');
    console.log(id,result,'delete orderCart')
    if(result){
        await deleteDoc(doc(db, 'orderCart', id)).then(()=>{
            alert("Your order Deleted Successfully");
            window.location.reload();
        }).catch(err=>{
            alert(err.message);
        })
    }
    
}

useEffect(()=>{

},[])

  return (
    <>
        {/* <Navbar/> */}

        { productList.length !==0 && <h1>Products List</h1> }
        <div className="products-container">
        {productList.length === 0 && <div>Slow Internet....OR No product to display.</div> }
            {productList.map(product=>(
                <div className="product-card" key={product.id} style={{ width: '500px' }}>
                    <div className="row"  style={{backgroundColor:'#94bbe9' ,width: '103%'}}>
                        {/* <div className="col-sm-5">
                            <img className="card-img" src={product.productImg} alt="image_here" width={180} height={180} style={{padding: '10px', borderRadius: '15%'}} />
                        </div> */}
                        <div className="col-sm-7 text-section" style={{paddingTop:'70px'}}>
                            {/* <div className="card-body"> */}
                                <h5 className="card-title fw-bold" style={{color:'#1200ff', fontWeight:'bold'}}>{product.fullName}</h5>
                                <p className="card-text" style={{fontWeight:'bold'}}>Email: {product.email}</p>
                                <p className="card-text" style={{fontWeight:'bold'}}>Contact: {product.contact}</p>
                                <p className="card-text" style={{fontWeight:'bold'}}>Address: {product.address}</p>
                                <p className="card-text" style={{fontWeight:'bold'}}>Total Price: {product.totalPrice}</p>

                                {
                                  product.shoppingCart && product.shoppingCart.map(cart =>(
                                      //console.log(cart, 'cart data')
                                      <div className='cart-card ' key={cart.id}>
                                          <div className='cart-img'>
                                              <img src={cart.productImg} alt='product img here' width={180} height={180} style={{padding: '10px', borderRadius: '15%'}} />
                                          </div>
                                          <div className='cart-name' >Product Name: {cart.productName}</div>
                                          <div className='cart-name' > Quantity: {cart.qty}</div>
                                          <div className='cart-price-orignal'>Price: {cart.productPrice}.00</div>
                                          {/* <div className='inc' onClick={()=>dispatch({type:'INC', id:cart.id, cart})}>
                                              <Icon icon={ic_add}></Icon>
                                          </div>
                                          <div className='quantity' >{cart.qty}</div>
                                          <div className='dec' onClick={() => dispatch({type:'DEC', id:cart.id, cart })}>
                                              <Icon icon={ic_remove} size={24}></Icon>
                                          </div> */}

                                          <div className='cart-price'>
                                              Total Product Price: {cart.TotalProductPrice}.00
                                          </div>

                                          {/* <button className='delete-btn' onClick={() => dispatch({type:'DELETE', id: cart.id, cart })}>
                                              <Icon icon={iosTrashOutline}></Icon>
                                          </button> */}
                                      </div>
                                  ))
                              }
                              <hr/>
                            <p className="card-text" style={{fontWeight:'bold'}}>Total Price: {product.totalPrice}</p>
                        </div>
                        <div className="d-flex justify-content-end" style={{backgroundColor:'#94e9bd'}}>
                            {role === 'admin' && <button type="button" className="btn btn-primary me-2" data-toggle="modal" data-target=".bd-example-modal-lg">Approve Order</button>}
                            <button className="btn btn-danger" onClick={()=>cancelOrder(product.id)} >Cancel Order</button>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    </>
  )
}

export default MyCart;



// const database = firebase.database();

// const userIdToFind = "some_value";

// database.ref('users').orderByChild('userId').equalTo(userIdToFind).once('value')
//   .then((snapshot) => {
//     if (snapshot.exists()) {
//       // Data found, use snapshot.val() to access the data
//       console.log(snapshot.val());
//     } else {
//       // Data not found for the given userId
//       console.log("Data not found.");
//     }
//   })
//   .catch((error) => {
//     console.error("Error fetching data:", error);
//   });

