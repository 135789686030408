import React from 'react';
import '../css/home.css';
// import Navbar from './navbar';
import Products from './product';
 
const Home = () => {
  return (
    <div className='wrapper'>
        {/* <Navbar/> */}
        <Products/>
    </div>
  )
}

export default Home;
