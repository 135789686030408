import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { auth } from  './config/config';
import { signInWithEmailAndPassword } from 'firebase/auth';
// import Navbar from './navbar';
// import { collection } from 'firebase/firestore';

const Login = () => {

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  
  const login = async(e) =>{
    e.preventDefault();

    if(email === '' || !email) {
      alert('Email is missing.');
      return;
    }
    else if(password === '' || !password)
    {
      alert('Password field cannot be empty.');
      return;
    }

    await signInWithEmailAndPassword(auth, email, password).then((userData)=>{
      let userEmail = userData.user.email;
      console.log(userEmail,'userData');
      setEmail('');
      setPassword('');
      setError('');
      alert('login successfull');
      navigate('/', { replace: true });
      window.location.reload();
    }).catch(err=>{
      setError(err.message);
      alert(`${err.message}`);
    });
  }

  useEffect(()=>{ 
    if(localStorage.getItem('auth')) navigate('/', { replace:true });
  },[navigate]);

  return (
    <>
    {/* <Navbar/> */}
      <div className='container'>
        <br />
        <h2>Login</h2>
        <br />
        <form autoComplete="off" className='form-group' onSubmit={login}>
            <label htmlFor="email">Email</label>
            <input type="email" className='form-control' required
                onChange={(e) => setEmail(e.target.value)} value={email} />
            <br />
            <label htmlFor="password">Password</label>
            <input type="password" className='form-control' required
                onChange={(e) => setPassword(e.target.value)} value={password} />
            <br />
            <button type="submit" className='btn btn-success btn-md mybtn'>LOGIN</button>
        </form>
        {error && <span className='error-msg'>{error}</span>}
        <br/>
        <span>Don't have an account? Register
            <Link to="/signup"> Here</Link>
        </span>
      </div>
    </>
  )
}

export default Login
