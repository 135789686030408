import React, {createContext} from "react";
import { db } from '../config/config';
import { collection, getDocs } from 'firebase/firestore';
//import { ref } from "firebase/storage";
//import { onValue } from "firebase/database";

//declearation of context must start with initial uppercase
export const ProductContext = createContext();

export class ProductContextProvider extends React.Component{

    //defining an initial state
    state={
        products:[]
    }

    componentDidMount  = async() => {
        var productData = collection(db, "products");
        var result = await getDocs(productData);
        //console.log(result, 'result');
        const finalData = result.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
            }));
            //console.log(finalData,'finaldata')
        this.setState({
            products: finalData
        });
    }

    // componentDidMount = () => {
    //     const dbRef = ref(db, "products");
    //     console.log(dbRef,'dbRef')
    //     onValue(dbRef, (snapshot) => {
    //       if (snapshot.exists()) {
    //         const productsData = snapshot.val();
    //         console.log(productsData,'productsData')
    //         const finalData = Object.keys(productsData).map((productId) => ({
    //           id: productId,
    //           ...productsData[productId],
    //         }));
    //         console.log(finalData,'finaldata')
    //         this.setState({
    //           products: finalData,
    //         });
    //       }
    //     });
    //   };

    render(){
        return(
            <ProductContext.Provider value={{products:{...this.state.products}}}>
                {this.props.children}
            </ProductContext.Provider>
        )
    }
}